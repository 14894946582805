@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@400;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400;500;700&display=swap");


* {
  // font-family: "Overpass", sans-serif;
  font-family: 'Baloo Da 2' !important;

  .setTitle {
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
  }


/* Base gauge styles */
.gauge__fill-success {
  background: #28a745; /* Green color for success */
}

.gauge__fill-info {
  background: #17a2b8; /* Blue color for info */
}

.gauge__fill-warning {
  background: #ffc107; /* Yellow color for warning */
}

.gauge__fill-danger {
  background: #dc3545; /* Red color for danger */
}

.gauge__fill-secondary {
  background: #6c757d; /* Grey color for secondary/default */
}

.gauge {
  width: 100%;
  max-width: 150px;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #004033;
  margin: 0 auto;
}

.gauge__body {
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: #b4c0be;
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
}

.gauge__fill {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  transform-origin: center top;
  transform: rotate(0.6turn); /* Adjust rotation for different percentages */
  transition: transform 0.2s ease-out;
}

.gauge__cover {
  width: 75%;
  height: 150%;
  background: #f6f8eb;
  border-radius: 50%;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25%;
  box-sizing: border-box;
}

.setText{
  padding-top: 10px;
}
.setText1{
  font-size: 14px;
}
.custom-hr {
  height: 4px;
    background-color: #b1b3a3;
    border: none;

}

/* InfoCard.css */
.info-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  
  margin-top: 20px;
}

.unique-class {
  font-size: 1rem;
  font-weight: bold;
}

.text-warning {
  color: #ffc107;
}

.text-primary {
  color: #007bff;
}

.text-purple {
  color: #6f42c1;
}

.text-success {
  color: #28a745;
}

.mb-0 {
  margin-bottom: 0;
}

.setUCSINFO{
  margin-left: 25px;
}

.info-card{
  background-color: white !important;
}


.setPOMO{
  width: 300px;
}


.setPM{
  margin-top: 10px;
}


.static-text1{
  position: absolute;
  padding-top: 170px;
  padding-left: 40px;


  
}

.static-text2{
  position: absolute;
  padding-top: 229px;
  padding-left: 33px;
}

.static-text3{
  position: absolute;
  padding-left: 628px;
  padding-top: 80px;
}

.static-text4{
  position: absolute;
  padding-left: 96px;
  padding-top: 354px;
}

.static-text5{
  padding-left: 620px;
  position: absolute;
  padding-top: 161px;
}

.static-text6{
    padding-left: 420px;
    position: absolute;
    padding-top: 379px;
}

.label-normal1{
    font-size: 16px;
    font-weight: bold;
    color: blue;
}

.label-normal2{
  font-size: 16px;
  font-weight: bold;
  color: red;
}

.label-normal3{
  font-size: 16px;
  font-weight: bold;
  color: red;
}

.label-normal4{
  font-size: 16px;
  font-weight: bold;
  color: green;
}

.label-normal5{
  font-size: 16px;
  font-weight: bold;
  color: green;
}

.label-normal6{
  font-size: 15px;
  font-weight: bold;
  color: red;
}

.setTExt{
  font-size: 12px;
}

.colorSet{
  background-color:#f3ff338f !important;
  font-weight:bold !important;
}

.UserText{
  width: 200px;
  border-radius: 50%;
}


/* GoogleMap.css */
.map-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  padding: 10px;
  border: 2px solid #ccc; /* Add a border to the container */
  border-radius: 8px;      /* Optional rounded corners */
  max-width: 100%;          /* Responsive: restricts the map to fit within its container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.map-container iframe {
  width: 100%;   /* Make iframe responsive to container width */
  height: 100%;  /* Make iframe responsive to container height */
  border-radius: 8px;  /* Optional rounding on iframe */
}

.setIMG{
  max-width: 30%;
  height: auto;
  text-align: center;
}

.setNUmber{
  font-size: 15px;
  font-weight: bold;
}

.TextSET{
  font-size: 15px;
}

.progress{
      --bs-progress-height: 5px !important;
}

.pomegranate-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin: auto;
  max-width: 100%;
  max-height: 155px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pomegranate-img {
  width: 60px;
  height: auto;
  margin-right: 15px;
}

.progress-bar {
  height: 5px;  /* Reduced height for the progress bar */
  background-color: #f44336;
}

.progress-value {
  font-size: 14px;
  font-weight: bold;
  color: #f44336;
}

.projected-yield {
  font-size: 12px;
  color: #777;
}

.card-title {
  font-size: 25px;
  font-weight: bold;
}

.text-danger {
  color: #f44336;
}

.fw-bold {
  font-weight: bold;
}

h5 {
  margin-top: 10px;
  font-size: 18px;
}


#videoPlayer1{
  width: 100%;
  height: auto;
  border-radius: 10px;
}

#heartIcon{
  color: red;
  cursor: pointer;
  position: absolute;
  // padding-top: 552px;
  padding-left: 12px;
  font-weight: bold;
}

#startIcon{
    position: absolute;
    // padding-top: 554px;
    // padding-left: 235px;
    color: red;
    font-weight: bold;
}

.SetplayButton{
  margin-left: 14px;
  position: absolute;
  width: 50px;
  padding-top: 70px
}

.VideoTitle{
  position: absolute;
  padding-top: 19px;
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
}

.VideoTitle1{
    position: absolute !important;
    padding-top: 300px !important;
    padding-left: 15px !important;
    font-size: 16px !important;
    font-weight: bold !important;
}


.container {
  max-width: 600px;
  margin: 0 auto;
}

.btn-option {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 50%; /* Making the button round */
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s;
  display: inline-block; /* Ensuring buttons are in the same row */
}

.btn-option:hover {
  background-color: #0056b3;
}

.btn-option.active {
  background-color: green; /* Active button color */
}

.line {
  display: inline-block;
  width: 22px; /* Adjust width for the connecting line */
  height: 3px; /* This defines the thickness of the line */
  background-color: var(--bs-green); /* Use a green color */
  margin: 0 -2px; /* Adjust margin to ensure lines are connected */
  vertical-align: middle; /* Align the line with the middle of the buttons */
}

.text-center {
  text-align: center;
}

img {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}


.pomegranate_nutriance{
  width: 306px;
  margin-left: 222px;
  margin-top: 57px;
}

.setCARD{
    position: absolute;
    width: 227px;
    padding-top: 10px;
    padding-left: 10px;
}

.setCARD1{
    width: 228px;
    position: absolute;
    padding-top: 124px;
    padding-left: 10px;
}


.setCARD2{
  width: 228px;
  position: absolute;
  padding-top: 238px;
  padding-left: 10px;
}

.setCARD12{
  margin-top: 88px !important;
  padding-left: 8px;
  width: 733px;
}

.setCARD3{
    width: 217px;
    padding-top: 10px;
    margin-left: 492px;
    position: absolute;
}

.setCARD4{
  position: absolute;
  padding-top: 124px;
  margin-left: 491px;
  width: 216px;
}

.setCARD5{
    position: absolute;
    margin-left: 493px;
    padding-top: 237px;
    width: 215px;
}

.setMArgin{
  margin-left: 10px;
}

.setHNB {
  border-radius: 50%;
  width: 25px;
  background-color: red;
  height: 25px;
  text-align: center;
  padding: 3px;
  color: white;
  font-weight: bold;
}


.setMP{
  padding-left: 32px;
  padding-right: 179px;
  text-align: justify;
  font-size: 15px;

}

.setmp1{
  margin-left: 85px;
  font-size: 25PX;
}

.SetColor{
  font-weight: bold;
  color: darkred;
}


.form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #333;
}

input[type="text"], select {
  width: 100%;
  // padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus, select:focus {
  border-color: #007bff;
  outline: none;
}

.btn-submit {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: #0056b3;
}

.gauge__fill-high {
  background-color: green; /* For 75% and above */
}

.gauge__fill-medium {
  background-color: yellow; /* For 50% to 74% */
}

.gauge__fill-low {
  background-color: orange; /* For 25% to 49% */
}

.gauge__fill-very-low {
  background-color: red; /* For below 25% */
}

.gauge__fill {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  transform-origin: center top;
  transition: transform 0.2s ease-out;
}


.text-success {
  color: green;
}

.bg-success {
  background-color: green;
}

.text-warning {
  color: yellow;
}

.bg-warning {
  background-color: yellow;
}

.text-orange {
  color: orange;
}

.bg-orange {
  background-color: orange;
}

.text-danger {
  color: red;
}

.bg-danger {
  background-color: red;
}



.setFASValue{
  font-size: 20px;
  font-weight: bold;
}

.SEtCOLOR{
  color: var(--bs-purple) !important;
}

@media (max-width: 768px) {
  .gauge1{
    padding-top: 10px;
  }

  .setMP {
    padding-left: 0px;
    padding-right: 0px; 
    text-align: justify;
    font-size: 15px;
  }

  .VideoTitle2{
    padding-top: 121!important;
    position: absolute !important;
    padding-left: 15px !important;
    font-size: 16px !important;
    font-weight: bold !important;
    }


  .setUCSINFO {
    margin-left: 0px;
  }
  .static-text1{
    position: absolute;
    padding-top: 68px;
    padding-left: 3px;
  }
  .static-text2{
    position: absolute;
    padding-top: 138px;
    padding-left: 1px;
  
  }

  .static-text3{
    position: absolute;
    padding-left: 309px;
    padding-top: 66px;

  }

  .static-text4{
    position: absolute;
    padding-left: 256px;
    padding-top: 225px;
  }

  .static-text5{
    padding-left: 309px;
    position: absolute;
    padding-top: 143px;
  }

  .static-text6{
    padding-left: 5px;
    position: absolute;
    padding-top: 213px;

  }
  
  .TextAlign{
    text-align: center;
  }
  
  .media-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.media-wrapper {
  position: relative;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
}


#startIcon {
  position: absolute;
  color: red;
  font-weight: bold;
  margin-left: 252px;
}

.SetIMG1{
  width: 264px;
  margin-left: 57px;
}


}






}



/* Hide on mobile (screen width less than 768px) */
.hide-on-mobile {
  display: none;
}

@media (min-width: 768px) {
  /* Show on desktop */
  .hide-on-mobile {
    display: block;
  }
}

/* Hide on desktop (screen width 768px and above) */
@media (min-width: 768px) {
  .hide-on-desktop {
    display: none;
  }
}

@media (max-width: 767px) {
  /* Show on mobile */
  .hide-on-desktop {
    display: block;
  }
}








.stepper-container {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row; /* Flex row to align stepper and content side-by-side */
  justify-content: center;
}

.stepper-vertical {
  display: flex;
  flex-direction: column; /* Stack the steps vertically */
  align-items: flex-start; /* Align steps to the left */
  margin-right: 20px; /* Space between stepper and content */
}

.step-container-vertical {
  display: flex;
  flex-direction: row; /* Arrange circle and label in a row */
  align-items: center;
  position: relative;
  margin-bottom: 40px; /* Space between steps */
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 18px;
  background-color: #ccc; /* Default color */
}

.circle.active {
  background-color: #4caf50; /* Active step color */
}

.label {
  margin-left: 20px; /* Add space to the right of the circle */
  text-align: left; /* Align the label text to the left */
}

.label small {
  display: block;
  color: #666;
}

.line-vertical {
  width: 5px;
  height: 100px;
  background-color: #ccc; /* Default line color */
  position: absolute;
  left: 18px; /* Center the line under the circle */
  top: 87px;
}

.line-vertical.active {
  background-color: #4caf50; /* Active line color */
}

.content {
  text-align: center;
  margin-left: 20px; /* Add space to the left of the content */
}

.buttons {
  margin-top: 20px;
}

.button {
  padding: 10px 20px;
  margin: 5px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.setTEXT2{
  font-size: 20px;
  margin-top: 20px;
}

.setTEXT3{
  margin-right: 20px;
  font-size: 20px;
  margin-top: 20px;
}

.setBackgroundcolor1{
  background-color: #f6f8eb;
  border-radius: 15PX;
}

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap');


* {
    font-family: "Overpass", sans-serif;
    
}

/* styles.css */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
  }
  
  .logo img {
    // width: 100px;
  }
  
  .nav-links {
    display: flex;
    gap: 20px;
  }
  
  .nav-links a {
    text-decoration: none;
    color: #333;
    font-size: 18px;
  }
  
  .nav-links a:hover {
    color: #007bff;
  }
  



// footer

footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f9fa;
    border-top: 1px solid #e7e7e7;
  }
  
  .footer-logo {
    margin-bottom: 10px;
  }
  
  .footer-logo img {
    height: 40px;
  }
  
  .footer-text {
    margin-bottom: 10px;
  }
  
  .footer-text a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
  }
  
  .footer-copyright {
    text-align: center;
    font-size: 14px;
    color: #555;
  }
  
  
.setTitle1121{
    text-align: center;
    font-size: 34px;
    font-family: 'Dancing Script', cursive !important;
    position: absolute;
    margin-top: 10px;

}

.setImagesStrok{
    width: 258px;
    cursor: pointer;
    margin-left: 62px;
    margin-top: 10px;

}
